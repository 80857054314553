import {
  ComponentRef,
  EmbeddedViewRef,
  inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ModalService<T> {
  private readonly document: Document = inject(DOCUMENT);
  private readonly rendererFactory: RendererFactory2 = inject(RendererFactory2);
  private readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  private componentRef: ComponentRef<T> | undefined;
  private _viewContainerRef: ViewContainerRef | undefined;

  set viewContainerRef(viewContainerRef: ViewContainerRef) {
    this._viewContainerRef = viewContainerRef;
  }

  public open(component: Type<T>): ComponentRef<T> | null {
    if (this.componentRef || !this._viewContainerRef) {
      return null;
    }

    this.componentRef = this._viewContainerRef.createComponent(component);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    this.renderer.appendChild(this.document.body, domElem);

    return this.componentRef;
  }

  public close(): void {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.destroy();
    this.componentRef = undefined;
  }
}
